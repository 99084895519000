import React from "react"
import PropTypes from "prop-types"

const LogoSesf = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 636.16 492.98">
      <g id="Calque_2" data-name="Calque 2">
        <g id="Ebene_2" data-name="Ebene 2">
          <rect className="cls-1" x="281.66" y="96.3" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="314.2" y="96.3" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="314.2" y="128.84" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="282.1" y="64.2" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="314.2" y="63.76" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="282.1" y="161.81" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="216.58" y="161.37" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="217.02" y="96.74" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="249.56" y="194.35" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="184.48" y="129.27" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="151.95" y="129.27" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="122.33" y="131.9" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="120.57" y="162.69" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="122.33" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="89.79" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="153.11" y="162.69" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="184.48" y="161.81" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="184.48" y="194.35" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="282.1" y="194.35" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="284.73" y="229.52" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="314.63" y="161.81" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="184.48" y="96.74" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="153.11" y="97.61" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="249.56" y="64.2" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="217.02" y="64.2" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="185.36" y="65.07" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="379.71" y="96.74" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="347.17" y="161.81" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="346.73" y="128.84" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="346.73" y="96.3" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="379.71" y="129.27" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="379.71" y="161.81" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="317.26" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="187.11" y="229.52" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="217.02" y="226.89" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="249.56" y="226.89" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="347.17" y="194.35" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="349.8" y="229.52" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="380.59" y="227.76" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="380.59" y="195.22" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="414.88" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="447.41" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="479.95" y="196.98" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="479.95" y="164.44" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="512.49" y="164.44" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="445.66" y="162.69" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="444.78" y="129.27" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="478.27" y="130.15" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="413.12" y="162.69" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="412.25" y="96.74" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="445.66" y="97.61" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="480.02" y="99.37" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="512.49" y="99.37" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="314.63" y="31.66" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="317.26" y="1.75" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="346.73" y="63.76" width="28.93" height="28.93" rx="1"/>
          <rect className="cls-1" x="379.71" y="64.2" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="347.17" y="31.66" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="379.71" y="31.66" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="413.12" y="65.07" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="413.12" y="32.54" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="282.1" y="31.66" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="250.44" y="32.54" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="217.9" y="32.54" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="348.05" width="26.3" height="26.3" rx="3"/>
          <rect className="cls-1" x="382.34" y="1.75" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="187.11" y="34.29" width="22.79" height="22.79" rx="5"/>
          <rect className="cls-1" x="412.25" y="129.27" width="28.05" height="28.05" rx="2"/>
          <rect className="cls-1" x="217.02" y="194.35" width="28.05" height="28.05" rx="2"/>
          <path fill="#fff"
                d="M311.02 128.4v29.8h-32.53v32.54h-29.81V158.2h-32.54v-29.8h32.54V95.86h29.81v32.54h32.53z"/>
        </g>
        <text transform="matrix(1.02 0 0 1 78.71 326.28)" fontSize="43.3" fontFamily="MyriadPro-Regular,Myriad Pro"
              letterSpacing="0" id="Ebene_4" data-name="Ebene 4">
          S
          <tspan x="21.38" y="0" letterSpacing=".02em">w</tspan>
          <tspan className="cls-5" x="54.23" y="0">i</tspan>
          <tspan className="cls-6" x="64.95" y="0">s</tspan>
          <tspan className="cls-7" x="82.71" y="0">s</tspan>
          <tspan x="112.24" y="0" letterSpacing=".03em">E</tspan>
          <tspan className="cls-9" x="134.7" y="0">s</tspan>
          <tspan x="152.47" y="0" letterSpacing=".03em">p</tspan>
          <tspan className="cls-11" x="178.41" y="0">o</tspan>
          <tspan className="cls-12" x="203.13" y="0">r</tspan>
          <tspan x="219.45" y="0" letterSpacing=".03em">t</tspan>
          <tspan className="cls-7" x="235.08" y="0">s</tspan>
          <tspan x="264.61" y="0" letterSpacing=".01em">F</tspan>
          <tspan x="286.04" y="0" letterSpacing=".03em">e</tspan>
          <tspan className="cls-16" x="308.98" y="0">d</tspan>
          <tspan x="334.35" y="0" letterSpacing=".02em">e</tspan>
          <tspan x="356.95" y="0" letterSpacing=".02em">r</tspan>
          <tspan x="372.09" y="0" letterSpacing=".01em">a</tspan>
          <tspan x="393.5" y="0" letterSpacing=".02em">t</tspan>
          <tspan className="cls-21" x="408.49" y="0">i</tspan>
          <tspan className="cls-22" x="419.52" y="0">o</tspan>
          <tspan className="cls-23" x="444.23" y="0">n</tspan>
          <tspan letterSpacing=".02em">
            <tspan x="-77.25" y="51.96">S</tspan>
            <tspan x="-54.88" y="51.96" letterSpacing=".01em">c</tspan>
            <tspan x="-34.87" y="51.96" letterSpacing=".01em">h</tspan>
            <tspan className="cls-27" x="-10.59" y="51.96">w</tspan>
            <tspan className="cls-21" x="21.82" y="51.96">e</tspan>
            <tspan className="cls-28" x="44.41" y="51.96">i</tspan>
            <tspan x="55.36" y="51.96">z</tspan>
            <tspan className="cls-30" x="74.68" y="51.96">er</tspan>
            <tspan x="112.36" y="51.96" letterSpacing=".01em">i</tspan>
            <tspan className="cls-32" x="123.08" y="51.96">s</tspan>
            <tspan x="141.2" y="51.96" letterSpacing=".01em">c</tspan>
            <tspan className="cls-34" x="161.21" y="51.96">h</tspan>
            <tspan className="cls-30" x="186.24" y="51.96">e</tspan>
            <tspan className="cls-7" x="208.84" y="51.96">r</tspan>
            <tspan className="cls-35" x="235.39" y="51.96">E</tspan>
            <tspan className="cls-9" x="257.85" y="51.96">s</tspan>
            <tspan className="cls-36" x="275.62" y="51.96">p</tspan>
            <tspan className="cls-22" x="301.56" y="51.96">o</tspan>
            <tspan className="cls-12" x="326.28" y="51.96">r</tspan>
            <tspan className="cls-37" x="342.6" y="51.96">t</tspan>
            <tspan className="cls-38" x="358.23" y="51.96">s</tspan>
            <tspan x="387.76" y="51.96" letterSpacing="-.02em">V</tspan>
            <tspan className="cls-40" x="411.12" y="51.96">er</tspan>
            <tspan x="448.79" y="51.96" letterSpacing=".03em">b</tspan>
            <tspan className="cls-42" x="474.52" y="51.96">a</tspan>
            <tspan className="cls-16" x="496.12" y="51.96">n</tspan>
            <tspan className="cls-23" x="521.09" y="51.96">d</tspan>
          </tspan>
          <tspan letterSpacing=".01em">
            <tspan x="-25.52" y="103.92">F</tspan>
            <tspan className="cls-44" x="-4.09" y="103.92">é</tspan>
            <tspan className="cls-16" x="18.86" y="103.92">d</tspan>
            <tspan className="cls-30" x="44.22" y="103.92">é</tspan>
            <tspan className="cls-32" x="66.82" y="103.92">r</tspan>
            <tspan x="81.96" y="103.92">a</tspan>
            <tspan x="103.37" y="103.92" letterSpacing=".02em">t</tspan>
            <tspan className="cls-21" x="118.36" y="103.92">i</tspan>
            <tspan className="cls-16" x="129.39" y="103.92">o</tspan>
            <tspan className="cls-7" x="154.11" y="103.92">n</tspan>
            <tspan className="cls-27" x="190.52" y="103.92">S</tspan>
            <tspan className="cls-42" x="212.41" y="103.92">u</tspan>
            <tspan className="cls-5" x="236.99" y="103.92">i</tspan>
            <tspan className="cls-6" x="247.72" y="103.92">s</tspan>
            <tspan x="265.47" y="103.92" letterSpacing=".02em">s</tspan>
            <tspan className="cls-38" x="283.62" y="103.92">e</tspan>
            <tspan x="317.7" y="103.92" letterSpacing=".03em">d</tspan>
            <tspan x="343.56" y="103.92" letterSpacing=".03em">‘</tspan>
            <tspan className="cls-35" x="353.79" y="103.92">E</tspan>
            <tspan className="cls-9" x="376.26" y="103.92">s</tspan>
            <tspan className="cls-36" x="394.02" y="103.92">p</tspan>
            <tspan className="cls-22" x="419.97" y="103.92">o</tspan>
            <tspan className="cls-12" x="444.68" y="103.92">r</tspan>
            <tspan className="cls-37" x="461.01" y="103.92">t</tspan>
            <tspan className="cls-38" x="476.63" y="103.92">s</tspan>
            <tspan x="-63.94" y="155.88">F</tspan>
            <tspan className="cls-44" x="-42.51" y="155.88">e</tspan>
            <tspan className="cls-16" x="-19.57" y="155.88">d</tspan>
            <tspan className="cls-30" x="5.8" y="155.88">e</tspan>
            <tspan className="cls-32" x="28.4" y="155.88">r</tspan>
            <tspan x="43.54" y="155.88" letterSpacing=".03em">a</tspan>
            <tspan x="65.52" y="155.88" letterSpacing=".02em">z</tspan>
            <tspan className="cls-21" x="84.91" y="155.88">i</tspan>
            <tspan className="cls-11" x="95.94" y="155.88">o</tspan>
            <tspan className="cls-34" x="120.66" y="155.88">n</tspan>
            <tspan className="cls-7" x="145.69" y="155.88">e</tspan>
            <tspan x="179.76" y="155.88" letterSpacing="-.01em">S</tspan>
            <tspan x="200.75" y="155.88" letterSpacing=".02em">v</tspan>
            <tspan className="cls-28" x="222.63" y="155.88">i</tspan>
            <tspan x="233.58" y="155.88" letterSpacing=".05em">z</tspan>
            <tspan x="254.23" y="155.88" letterSpacing=".02em">z</tspan>
            <tspan className="cls-40" x="273.56" y="155.88">e</tspan>
            <tspan className="cls-32" x="296.16" y="155.88">r</tspan>
            <tspan className="cls-23" x="311.3" y="155.88">a</tspan>
            <tspan className="cls-42" x="344.55" y="155.88">d</tspan>
            <tspan className="cls-38" x="369.7" y="155.88">i</tspan>
            <tspan className="cls-35" x="392.21" y="155.88">E</tspan>
            <tspan className="cls-9" x="414.68" y="155.88">s</tspan>
            <tspan className="cls-36" x="432.45" y="155.88">p</tspan>
            <tspan className="cls-22" x="458.39" y="155.88">o</tspan>
            <tspan className="cls-12" x="483.11" y="155.88">r</tspan>
            <tspan className="cls-37" x="499.43" y="155.88">t</tspan>
            <tspan className="cls-38" x="515.06" y="155.88">s</tspan>
          </tspan>
        </text>
      </g>
    </svg>
  )
}

LogoSesf.propTypes = {

}

export default LogoSesf
