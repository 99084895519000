/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"
import { motion } from "framer-motion"
import _ from "lodash"
import Header from "./header"
import "../SASS/styles.scss"
import Footer from "./footer"
import useWindowSize from "../hooks/useWindowSize"
import BackgroundSvg from "./home/background-svg"

const Layout = ({ctx, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const size = useWindowSize()

  const scrollContainer = useRef()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleListInterview = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const scrollConfig = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }

  useEffect(() => {
    isMenuOpen
      ? (document.body.style.height = "")
      : (document.body.style.height = `${
          scrollContainer.current.getBoundingClientRect().height
        }px`)
  }, [size.height])

  useEffect(() => {
    requestAnimationFrame(() => smoothScrolling())
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.position = "fixed"
    } else {
      document.body.style.position = ""
    }
  }, [isMenuOpen])

  useEffect(() => {
    let bgColor = "#F2F2F2"
    let colorText = "#2c2c2c"
    if(ctx === 'about'){
      bgColor = "#000000"
      colorText = "#F2F2F2"
    }
    document
      .documentElement.style.setProperty("--color-background", bgColor)
      document
        .documentElement.style.setProperty("--color-text", colorText)
  }, [])

  const smoothScrolling = () => {
    scrollConfig.current = window.scrollY
    scrollConfig.previous +=
      (scrollConfig.current - scrollConfig.previous) * scrollConfig.ease
    scrollConfig.rounded = Math.round(scrollConfig.previous * 100) / 100

    // variables
    const difference = scrollConfig.current - scrollConfig.rounded
    const acceleration = difference / window.width
    const velocity = +acceleration

    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0,-${scrollConfig.rounded}px,0)`
    }

    requestAnimationFrame(() => smoothScrolling())
  }

  const layoutVariants = {
    visible : {
      opacity: 1,
    },
    hidden : {
      opacity: 0,
    },
    exit : {
      opacity: 0,
      y: 30
    }
  }

  return(

  <TransitionState>
    {({ transitionStatus, exit, entry, mount }) => {
      return (
        <motion.div className={`scrollcontainer ${ctx}`} ref={scrollContainer} initial={"hidden"} animate={mount ? "visible" : "hidden"} variants={layoutVariants}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            toggleListInterview={toggleListInterview}
            isMenuOpen={isMenuOpen}
          />
          <div className="main-container">
            <div>
              <main>{children}</main>
            </div>
          </div>
          <Footer ctx={ctx} />
        </motion.div>
      )
    }}
  </TransitionState>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
