import { Link } from "gatsby"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import LogoHeader from "./logo-header"
import NavigationInterview from "./navigation-interviews"
import LogoSesf from "./logo-sesf"
import TransitionLink from "gatsby-plugin-transition-link"

const Header = ({ siteTitle, toggleListInterview, isMenuOpen }) => {
  return (
    <header>
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={"navbar"}
      >
        <div className="nav-left">
          <TransitionLink
            to={"//sesf.ch"}
            className="sesf-logo"
            exit={{
              length: 0.3,
            }}
            entry={{
              delay: 0.3,
            }}
          >
            <LogoSesf />
          </TransitionLink>
        </div>
        <div className="nav-center">
          <TransitionLink
            to={"/"}
            className="members-logo"
            exit={{
              length: 0.3,
            }}
            entry={{
              delay: 0.3,
            }}
          >
            <LogoHeader />
            <span className="logo-mentions">sesf members</span>
          </TransitionLink>
        </div>
        <div className={"nav-right"}>
          <button className="navbar__navtoggle">
            <svg
              viewBox="0 0 100 80"
              width="40"
              height="40"
              onClick={toggleListInterview}
            >
              <rect width="100" height="10" />
              <rect y="30" width="100" height="10" />
              <rect y="60" width="100" height="10" />
            </svg>
          </button>
          <AnimatePresence initial={false}>
            {isMenuOpen && (
              <NavigationInterview toggleListInterview={toggleListInterview} />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
