import React from "react"
import PropTypes from "prop-types"

const LogoMembership = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 654 654">
      <g data-name="Calque 2">
        <g data-name="3 – Reduced">
          <path d="M647.32 523l-124.42-.07L327 327l195.67-195.67 125 .07a5.5 5.5 0 015.5 5.5l.07 125-65.22 65.23 66 65.95-1.18 124.42a5.5 5.5 0 01-5.52 5.5z" fill="#ffdf43"/>
          <path d="M131 6.68l.07 124.42L327 327l195.67-195.67-.07-125A5.5 5.5 0 00517.1.8l-125-.07L326.84 66 260.89 0 136.47 1.18a5.5 5.5 0 00-5.47 5.5z" fill="#a2c73b"/>
          <path d="M523 647.32l-.07-124.42L327 327 131.33 522.67l.07 125a5.5 5.5 0 005.5 5.5l125 .07 65.23-65.22 65.95 66 124.42-1.18a5.5 5.5 0 005.5-5.52z" fill="#39b2b9"/>
          <path d="M6.68 131l124.42.07L327 327 131.33 522.67l-125-.07a5.5 5.5 0 01-5.5-5.5l-.07-125L66 326.84 0 260.89l1.18-124.42a5.5 5.5 0 015.5-5.47z" fill="#cb0a00"/>
          <path fill="#fff" d="M106.68 445.38v25.67H78.65v28.04H52.97v-28.04H24.93v-25.67h28.04v-28.04h25.68v28.04h28.03z"/>
        </g>
      </g>
    </svg>
  )
}

LogoMembership.propTypes = {

}

export default LogoMembership
