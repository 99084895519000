import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useListInterviews } from "../hooks/list-interviews"
import { AnimatePresence, motion } from "framer-motion"
import Img from "gatsby-image"

const NavigationInterview = ({ toggleListInterview }) => {
  const [showLogo, setShowLogo] = useState({
    show: false,
    logo: "",
    key: 0,
  })

  const listInterviews = useListInterviews()

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        delay: 0.3,
        staggerChildren: .7
      }
    }
  }

  const itemVariants = {
    hidden: {
      y: 10,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      }
    },
  }

  const itemItemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      }
    }
  }

  const variants = {
    show : {
      y: 0,
      transition: {
        duration: .7
      }
    },
    hidden: {
      y: "100%"
    },
    transition: {
      duration: 3
    }
  }

  return (
    <motion.div
      key={"itw-nav"}
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      transition={{ duration: 0.45 }}
      className={"interviews-navigation"}
    >
      <button
        className={"interviews-navigation__close"}
        onClick={toggleListInterview}
      >
        <svg viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
        close
      </button>
      <AnimatePresence>
        <motion.div className="interviews-navigation-wrapper" variants={containerVariants} initial="hidden" animate="show">
          {listInterviews.map(interview => (
            <motion.div
              className={"itw-nav-item"}
              key={interview.node.id}
              variants={itemVariants}
              onHoverStart={() =>
                setShowLogo({
                  show: true,
                  logo: interview.node.frontmatter.logo.childImageSharp.fluid,
                  key: interview.node.id,
                })
              }
              onHoverEnd={() =>
                setShowLogo({
                  show: false,
                  logo: interview.node.frontmatter.logo.childImageSharp.fluid,
                  key: interview.node.id,
                })
              }
            >
              <motion.span className="informations" variants={itemItemVariants}>
                <b>{interview.node.frontmatter.respondent}</b>{" "}
                {interview.node.frontmatter.role}
              </motion.span>
              <Link className="teamname" to={"/" + interview.node.fields.slug}>
                <motion.div
                  initial={{ x: "-4rem" }}
                  whileHover={{ x: 0 }}
                  className="link"
                  variants={itemItemVariants}
                >
                  <motion.svg
                    initial={{ x: 10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    viewBox="0 0 492 492"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        d="M509.585,249.996l-174.481-179.2c-3.208-3.302-9.02-3.302-12.228,0l-47.386,48.666c-3.234,3.319-3.226,8.619,0.017,11.93
        l80.307,81.894H8.533c-4.71,0-8.533,3.823-8.533,8.533v68.267c0,4.71,3.823,8.533,8.533,8.533h347.281l-80.307,81.894
        c-3.243,3.311-3.251,8.61-0.017,11.93l47.386,48.666c1.604,1.647,3.806,2.577,6.11,2.577c2.304,0,4.506-0.93,6.118-2.577
        l174.481-179.2C512.811,258.589,512.811,253.307,509.585,249.996z"
                      />
                    </g>
                  </motion.svg>
                  {interview.node.frontmatter.title}
                </motion.div>
              </Link>
            </motion.div>
          ))}
          <p className="more-to-comes">And many more to come...</p>
        </motion.div>
      </AnimatePresence>
      <div className="logo-placeholder">
        <AnimatePresence>
          <motion.div className="logo-placeholder__bg_black" initial="hidden" animate="show" exit="hidden" variants={variants}/>
        </AnimatePresence>
        <AnimatePresence initial={false} exitBeforeEnter>
          {showLogo.show && (
            <motion.div
              key={showLogo.key}
              className="logo-team"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Img fluid={showLogo.logo} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

NavigationInterview.propTypes = {}

export default NavigationInterview
