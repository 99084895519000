import { useStaticQuery, graphql } from "gatsby"

export const useListInterviews = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query listInterviews {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/interview/"}}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                date
                respondent
                role
                title
                logo {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}
