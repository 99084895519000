import React from "react"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"

const AnimLink = ({ children, className, to }) => {
  return (
    <TransitionLink
      to={to}
      className={className}
      exit={{
        length: 0.3,
      }}
      entry={{
        delay: 0.3,
      }}
    >
      {children}
    </TransitionLink>
  )
}

AnimLink.propTypes = {}

export default AnimLink
