import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    socialCard
                }
            }
            file(name: {eq: "logo_image"}) {
                publicURL
            }
        }
    `,
  );
  return site.siteMetadata;
};

export default useSiteMetadata;