import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import AnimLink from "../utils/anim-link"

const Footer = ({ ctx }) => {
  const data = useStaticQuery(graphql`
    query {
        mainSponsor: file(relativePath: {eq: "sponsor/Swisscom Logos.png"}){
            childImageSharp{
                fixed(height: 130) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
        btwSponsor: file(relativePath: {eq: "sponsor/BTW_Logo_SW_Quadrat-293x300.jpg"}){
            childImageSharp{
                fixed(height: 130) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
        wideSponsor: file(relativePath: {eq: "sponsor/RVB-WIDE Horizontale_Rouge.png"}){
            childImageSharp{
                fixed(height: 130, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
        mainSponsorBn: file(relativePath: {eq: "sponsor/Swisscom Logos_b&w.png"}){
            childImageSharp{
                fixed(height: 130) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
        btwSponsorBn: file(relativePath: {eq: "sponsor/BTW_Logo_SW_pos.jpg"}){
            childImageSharp{
                fixed(height: 130) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
        wideSponsorBn: file(relativePath: {eq: "sponsor/RVB-WIDE Horizontale_B&W.png"}){
            childImageSharp{
                fixed(height: 130, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
    }
  `)

  const isAbout = ctx && ctx === 'about'
  const logoSwisscom =  isAbout ? data.mainSponsorBn : data.mainSponsor
  const logoBTW = isAbout ? data.btwSponsorBn : data.btwSponsor
  const logoWide = isAbout ? data.wideSponsorBn : data.wideSponsor

  return (
    <footer className={"footer"}>
      <div className="footer-row sponsors">
        <div className="sponsor">
          <h3 className="sponsors-title">Main sponsor</h3>
          <div className="sponsors-logo">
            <a href="https://www.swisscom.ch/">
              {console.log(ctx)}
              <Img fixed={logoSwisscom.childImageSharp.fixed} />
            </a>
          </div>
        </div>
        <div className="partners-right">
          <div className="sponsor">
            <h3 className="sponsors-title">Our partners</h3>
            <div className="partners">
              <div className="sponsors-logo">
                <a href="#">
                  <Img fixed={logoBTW.childImageSharp.fixed} />
                </a>
              </div>
              <div className="sponsors-logo">
                <a href="https://www.wideagency.ch/">
                  <Img fixed={logoWide.childImageSharp.fixed} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-row">
        <div className="copyright">
          <p>© 2020 SESF</p>
        </div>
        <div className="footer__right">
          <div className="footer__right__links">
            <AnimLink to={'/about'}>About</AnimLink>
            <AnimLink to={'/privacy'}>Privacy</AnimLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
